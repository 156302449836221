<template>
  <v-container fluid class="pa-0 mb-6">
    <v-row justify="center">
      <v-col cols="12">
        <v-img height="634px" class="align-center" :src="get_image.img_0">
          <v-row>
            <v-col
              lg="7"
              cols="12"
              :class="$vuetify.breakpoint.smAndDown ? 'text-center' : ''"
            >
              <div
                v-if="!$vuetify.breakpoint.smAndDown"
                @click="editar('coverage.title')"
                v-html="$t('coverage.title')"
                class="textoImagenPc"
              ></div>
              <div
                v-else
                @click="editar('coverage.title_mobil')"
                v-html="$t('coverage.title_mobil')"
                class="textoImagenMobile"
              ></div>
            </v-col>
          </v-row>
        </v-img>
      </v-col>
      <v-col
        v-if="!$vuetify.breakpoint.smAndDown"
        cols="11"
        style="margin-top: 123px"
      >
        <v-img
          :aspect-ratio="16 / 12"
          max-height="1000px"
          position="top right"
          class="align-end"
          :src="get_image.img_1"
        >
          <v-row no-gutters style="margin-bottom: 30%">
            <v-col
              cols="12"
              class="text-center tituloSecundario"
              @click="editar('coverage.coverage')"
            >
              {{ $t('coverage.coverage') }}</v-col
            >
            <v-col
              cols="12"
              class="text-center textoMobil"
              @click="editar('coverage.c_details')"
              >{{ $t('coverage.c_details') }}</v-col
            >
          </v-row>
          <v-row align="end" class="mb-12">
            <v-col cols="4">
              <div
                @click="editar('coverage.mex')"
                class="miniTituloSimple ml-3 mb-6"
                v-html="$t('coverage.mex')"
              ></div>
              <v-row no-gutters>
                <v-col
                  class="textoSimple"
                  v-for="(m, i) in $t('coverage.c_mex')"
                  :key="i"
                  cols="5"
                >
                  {{ m }}
                </v-col>
              </v-row>
            </v-col>
            <v-spacer></v-spacer>
            <v-col align-self="start" cols="5">
              <div
                @click="editar('coverage.usa')"
                class="miniTituloSimple ml-3 mb-6"
              >
                {{ $t('coverage.usa') }}
              </div>
              <v-row no-gutters>
                <v-col
                  class="textoSimple"
                  v-for="(m, i) in $t('coverage.c_usa')"
                  :key="i"
                  cols="12"
                >
                  {{ m }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-img>
      </v-col>
      <v-col v-else cols="12">
        <v-row justify="center" style="margin-top: 100px">
          <v-col
            @click="editar('coverage.coverage')"
            cols="11"
            class="titulo text-center"
          >
            {{ $t('coverage.coverage') }}
          </v-col>
          <v-col
            @click="editar('coverage.c_details')"
            cols="8"
            class="parrafoTechnology mt-2"
          >
            {{ $t('coverage.c_details') }}
          </v-col>
          <v-col cols="11">
            <v-img
              position="top center"
              :aspect-ratio="10 / 28"
              :src="get_image.img_2"
            >
              <v-row justify="center">
                <v-col
                  cols="12"
                  class="text-center parrafoTechnology"
                  style="line-height: 23px"
                >
                  <div
                    @click="editar('coverage.mex')"
                    v-html="$t('coverage.mex')"
                  ></div>
                </v-col>
                <v-col cols="12">
                  <v-img :src="get_image.img_3" />
                </v-col>
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col
                      class="textoSimple"
                      v-for="(m, i) in $t('coverage.c_mex')"
                      :key="i"
                      cols="5"
                    >
                      {{ m }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="text-center" style="margin-top: 100px">
                  <div @click="editar('coverage.usa')" class="textoSimple">
                    {{ $t('coverage.usa') }}
                  </div>
                  <v-row no-gutters>
                    <v-col
                      class="textoSimple"
                      v-for="(m, i) in $t('coverage.c_usa')"
                      :key="i"
                      cols="12"
                    >
                      {{ m }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-img :src="get_image.img_4" />
                </v-col>
              </v-row>
            </v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { COVERAGE } from '@/assets/img/images';
export default {
  nuxtI18n: {
    paths: {
      en: '/coverage',
      es: '/cobertura',
    },
  },
  metaInfo() {
    return {
      title: this.$t('coverage.seo.title'),
      meta: [
        { name: 'descripción', content: this.$t('coverage.seo.description') },
        { name: 'keywords', content: this.$t('coverage.seo.keywords') },
      ],
    };
  },

  data() {
    return {};
  },
  computed: {
    get_image() {
      return COVERAGE;
    },
  },
};
</script>
